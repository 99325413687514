.app {
  margin: 10px;
  min-width: 300px;
  max-width: 600px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

@media only screen and (max-device-width: 480px) {
  .app {
    width: 100% !important;
    margin: 0px !important;
    border: 0px !important;
  }
}

.app-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  padding: 12px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.tabs-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.input-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.input-container2 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 24px;
}
.output-header-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}
.output-body-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-left: 12px;
}
.output-total-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
}
.loading-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.confirm-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.flex-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;
}
.spacing {
  margin: 10px;
}
.line {
  width: "100%";
  border: 1px solid rgba(0, 0, 0, 0.12);
}
